<template>
  <div>

    <div 
      class="
        d-flex 
        justify-start 
        align-center" 
      style="
        height: 60px;
        color: #d31145;
        background-color: #fff;
        padding: 0 20px 0 20px;">

      <v-icon 
        color="#d31145" 
        @click="$router.push('/inventory')">

        mdi-arrow-left
      </v-icon>

      <span 
        style="
          padding-left: 15px;
          font-weight: 700">

        Penyesuaian Stok
      </span>

    </div>
    
    <v-divider></v-divider>

    <div>

      <div 
        v-if="stock_adjustment_list.length > 0 || searching != ''"
        class="
          d-flex 
          justify-space-around
          align-center 
          pa-4"
        style="
          background-color: #fff;
          height:60px">

        <v-text-field
          dense
          v-model="searching"
          :hide-details="true"
          color="#ACACAC"
          filled
          rounded
          placeholder="Search..."
          elevation="0">
          <template v-slot:prepend-inner>
            <v-icon 
              color="#ACACAC">
              mdi-magnify
            </v-icon>
          </template>

          <template v-slot:append>
            <v-icon 
              color="#FF2353"  
              v-show="searching != ''"
              @click="
                searching = '';
                initialize()">
              mdi-close-circle
            </v-icon>
          </template>
        </v-text-field>

        <v-btn 
          icon 
          class="ml-4" 
          color="#d31145">

          <v-icon>
            mdi-filter-variant
          </v-icon>

        </v-btn>

      </div>

      <v-card 
        flat 
        v-if="stock_adjustment_list.length < 1 && searching == '' && !process.run">

        <v-card-text 
          style="
          padding: 20px 20px">

          <div 
            style="
              font-weight: 700;
              font-size:16px;
              padding-bottom: 5px">

            Anda belum menambahkan Penyesuaian Stok
          </div>

          <div 
            style="
              font-weight: normal;
              font-size: 14px;
              color:#ACACAC;
              line-height: 1.5;
              padding-bottom: 5px">

            Anda bisa menambahkan dengan menekan tombol di bawah.
          </div>

          <div 
            style="padding-top:20px">

            <v-btn 
              @click="dialog.form = true"
              rounded 
              depressed 
              color="#d31145" 
              elevation="0" 
              class="white--text text-capitalize">

              <v-icon left>
                mdi-plus
              </v-icon>

              Tambahkan
              
            </v-btn>

          </div>

        </v-card-text>

      </v-card>

      <v-card 
        flat 
        v-else-if="stock_adjustment_list.length < 1 && 
        searching != ''">

        <v-card-text
          class="d-flex align-center"
          style="
            height: calc(100vh - 200px); 
            background: #f9f9f9; ">

          <v-img
            class="mx-auto"
            max-width="200"
            src="https://sodapos.com/media/2021/12/image/no-result-1638951068.png">
          </v-img>

        </v-card-text>

      </v-card>

      <v-card flat v-else>
        <v-card-text 
          class="px-0 pb-0 py-0" 
          style="
            overflow: auto; 
            height: calc(100vh - 210px);
            background: #f9f9f9;
            padding-bottom: 65px !important;">

            <div v-if="stock_adjustment_list.length < 1 && searching == '' && process.run">
              <v-skeleton-loader
                v-for="item in 15" :key="item"
                type="list-item-avatar-two-line">
              </v-skeleton-loader>
            </div>

          <v-list 
            dense 
            subheader 
            three-line 
            class="py-0">

            <template 
              v-for="(item, i) in stock_adjustment_list">

              <v-list-item 
                :key="item.id" 
                class="py-0">

                <v-list-item-avatar 
                  tile 
                  size="100" 
                  style="
                    border-radius:5px !important">

                  <v-img
                    width="100"
                    height="100"
                    :src="item.image_url">

                    <template v-slot:placeholder>

                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">

                        <v-progress-circular
                          indeterminate
                          color="#d31145">
                        </v-progress-circular>

                      </v-row>

                    </template>

                  </v-img>

                </v-list-item-avatar>

                <v-list-item-content>

                  <v-list-item-title 
                    style="font-size: 16px;" 
                    class="
                      font-weight-bold 
                      text-title 
                      text-capitalize">

                    {{ item.name }}

                    <div class="mt-2">

                      <div 
                        class="d-flex align-center mb-1">

                        <v-icon color="#FA91AD">
                          mdi-store
                        </v-icon>

                        <div class="ml-3 text-red">

                          {{ item.store_name }}

                        </div>
                        
                      </div>

                      <div 
                        class="d-flex align-center text-title">

                        <v-icon color="#FA91AD">
                          mdi-account-tie
                        </v-icon>

                        <div class="ml-3">

                          {{ item.user_name }}

                        </div>

                      </div>

                    </div>

                  </v-list-item-title>

                </v-list-item-content>

              </v-list-item>

              <v-list-item :key="i"  class="py-0">

                <v-list-item-content>

                  <v-simple-table class="py-0" dense>

                    <template>

                      <tbody>

                        <tr>
                          <th class="text-start px-0 py-0" style="font-weight: 400" width="30%">Stok Sebelum</th>
                          <td class="text-center text-second px-0">:</td>
                          <td class="text-start text-title pl-5 pr-0">
                            {{ item.before }}
                          </td>
                        </tr>

                        <tr>
                          <th class="text-start px-0" style="font-weight: 400" width="30%">Stok Penyesuaian</th>
                          <td class="text-center text-second px-0">:</td>
                          <td class="text-start text-title pl-5 pr-0">
                            {{ item.adjustment }}
                          </td>
                        </tr>

                        <tr>
                          <th class="text-start px-0" style="font-weight: 400" width="30%">Stok Sesudah</th>
                          <td class="text-center text-second px-0">:</td>
                          <td class="text-start text-title pl-5 pr-0">
                            {{ item.after }}
                          </td>
                        </tr>

                      </tbody>

                    </template>

                  </v-simple-table>

                  <v-list-item-subtitle 
                    class="
                      text-end 
                      pt-0 
                      font-italic" 
                    style="
                      color: #ACACAC;
                      font-size: 14px">

                    {{ item.input_datetime | dateFull }}

                  </v-list-item-subtitle>

                </v-list-item-content>

              </v-list-item>

              <v-divider :key="i" class="mx-auto"></v-divider>

            </template>
            
          </v-list>

        </v-card-text>

      </v-card>

    </div>

    <div 
      v-if="stock_adjustment_list.length > 0" 
      class="
        d-flex 
        justify-center 
        align-center">

      <v-btn 
        class="
          float 
          white--text 
          text-capitalize" 
        @click="dialog.form = true" 
        style="right:100px" 
        rounded 
        color="#D31145">

        <v-icon 
          color="#fff" 
          class="my-float" l
          eft>
          mdi-plus
        </v-icon>
        
        Tambahkan
      </v-btn>

      <v-btn 
        class="
          float 
          white--text 
          text-capitalize" 
          style="right:260px;" 
          rounded 
          color="#207245">

        <v-icon 
          color="#fff" 
          class="my-float" 
          left>
          mdi-microsoft-excel
        </v-icon>

        Export Excel
      </v-btn>
    </div>

    <v-dialog 
      v-model="dialog.selected"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      fullscreen>
      <v-card>

        <v-card-title 
          style="
            height: 60px;
            color: #d31145;
            background-color: #fff;
            padding: 0 20px 0 20px;">

          <v-icon 
            @click="dialog.selected = false" 
            color="#d31145">
            mdi-arrow-left
          </v-icon>

          <span 
            style="
              padding-left:15px;
              font-weight: 700;
              font-size: 20px">
            List Produk
          </span>

        </v-card-title>
        
        <v-divider></v-divider>

        <v-card-text 
          class="px-0 py-0" 
          style="height:300px">

          <div 
            v-if="product_list.length > 0 || searching_product !== ''" 
            class="
              d-flex 
              justify-space-around 
              align-center pa-4" 
            style="
              background-color: #ffffff;
              height: 60px">
              
            <v-text-field
              dense
              v-model="searching_product"
              :hide-details="true"
              color="#ACACAC"
              filled
              rounded
              placeholder="Search..."
              elevation="0">
              <template v-slot:prepend-inner>
                <v-icon 
                  color="#ACACAC">
                  mdi-magnify
                </v-icon>
              </template>

              <template v-slot:append>
                <v-icon 
                  color="#FF2353"  
                  v-show="searching_product != ''"
                  @click="
                    searching_product = '';
                    getProduct()">
                  mdi-close-circle
                </v-icon>
              </template>
            </v-text-field>
          </div>

          <v-card 
            flat 
            v-if="product_list.length < 1 && searching_product == '' && !process.run">

            <v-card-text 
              style="padding: 20px 20px">

              <div 
                style="
                  font-weight: 700;
                  font-size: 16px;
                  padding-bottom: 5px">

                Anda belum menambahkan Produk
              </div>

              <div 
                style="
                  font-weight: normal;
                  font-size: 14px;
                  color: #ACACAC;
                  line-height: 1.5; 
                  padding-bottom: 5px">

                Belum ada produk atau layanan pada toko ini. 

                <br>

                Anda bisa menambahkan dengan menekan tombol di bawah.
              </div>

              <div style="padding-top:20px">

                <v-btn 
                  @click="dialog.form = true" 
                  rounded 
                  depressed 
                  color="#d31145" 
                  elevation="0" 
                  class="white--text text-capitalize">

                  <v-icon left>
                    mdi-plus
                  </v-icon>

                  Produk
                </v-btn>

              </div>

            </v-card-text>

          </v-card>

          <v-card
            v-else
            flat
            class="mx-auto">

            <div 
              v-if="product_list.length < 1 && searching == '' && 
              process.run">

              <v-row class="py-2">

                <v-col cols="12" v-for="item in 15" :key="item">

                  <v-skeleton-loader
                    class="rounded-lg"
                    type="list-item-avatar-two-line">
                  </v-skeleton-loader>

                </v-col>

              </v-row>

            </div>

            <v-card 
              flat 
              v-else-if="product_list.length < 1 && searching != ''">

              <v-card-text
                class="d-flex align-center"
                style="
                  height: calc(100vh - 200px); 
                  background: #f9f9f9; ">

                  <v-img
                    class="mx-auto"
                    max-width="200"
                    src="https://sodapos.com/media/2021/12/image/no-result-1638951068.png">
                  </v-img>

              </v-card-text>

            </v-card>

            <v-list class="py-0" v-else>

              <v-list-item-group>

                <v-list-item
                  v-for="(item, i) in product_list"
                  :key="i"
                  @click="addData(item, i)">

                  <v-list-item-avatar tile class="rounded-lg">

                    <v-img
                      width="50"
                      height="50"
                      :src="item.image_url">

                      <template v-slot:placeholder>

                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center">

                          <v-progress-circular
                            indeterminate
                            color="#d31145">
                          </v-progress-circular>

                        </v-row>

                      </template>

                    </v-img>

                  </v-list-item-avatar>

                  <v-list-item-content>

                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle class="text-red"> {{ item.sku }} </v-list-item-subtitle>

                  </v-list-item-content>

                  <v-list-item-icon>

                    <v-icon color="#D0D0D0">
                      mdi-chevron-right
                    </v-icon>
                  
                  </v-list-item-icon>
                
                </v-list-item>
              
              </v-list-item-group>
            
            </v-list>
          
          </v-card>
        
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.form"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      fullscreen>

      <v-card flat>

        <v-card-title 
          style="
            height: 60px;
            color: #d31145;
            background-color: #fff;
            padding: 0 20px 0 20px;">

          <v-icon 
            @click="closeDialog()" 
            color="#d31145">
            mdi-arrow-left
          </v-icon>

          <span 
            style="
              padding-left: 15px;
              font-weight: 700;
              font-size: 20px">
            Penyesuaian Stok
          </span>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text 
          class="px-5" 
          style="
            height: 100vh; 
            padding-bottom: 70px">

          <div class="pt-5">

            <div 
              class="d-flex justify-space-between">

              <div class="mb-2 subtitle-2 font-weight-bold">

                Tambahkan Produk

                <span style="color: #d31145;" class="caption">
                  ( Wajib )
                </span>

              </div>

              <div 
                @click="openDialog()"
                style="
                  color: #d31145;
                  cursor: pointer;" 
                class="text-capitalize body-2">

                <v-icon 
                  size="20" 
                  color="#d31145">
                  mdi-plus
                </v-icon>

                Tambahkan
              </div>
            </div>

            <div v-if="product_selected.length > 0">
              
              <v-list dense class="px-0 py-0">
                <template v-for="(item, i) in product_selected">
                  <v-list-item :key="item.id" class="px-0">
                    <v-list-item-content>
                      
                      <!-- <v-list-item-title class="d-flex justify-space-between align-center pt-5">
                      </v-list-item-title> -->

                      <div class="py-2">

                        <div 
                          class="
                            mb-2 
                            d-flex 
                            justify-space-between 
                            align-center" 
                          style="
                          color: #FA91AD;
                            font-size: 20px">

                          {{ item.name }}

                          <v-icon 
                            @click="removeProduct(i)" 
                            color="#d31145">
                            mdi-delete
                          </v-icon>

                        </div>

                        <div 
                          style="
                            font-size: 13px; 
                            font-weight: 600"
                          class="text-title">

                          SKU : {{ item.sku }}
                        </div>

                        <v-list-item-subtitle class="mt-7">

                          <v-row>

                            <v-col cols="12">

                              <div 
                                class="text-title font-weight-bold" 
                                style="font-size: 13px">

                                Jumlah Penyesuaian
                              </div>

                              <v-currency-field
                                v-model="item.qty"
                                placeholder="Tuliskan disini"
                                required
                                color="#d31145"
                                maxlength="50"
                                autocomplete="off"
                                hide-details="auto"
                                class="py-0"
                                style="padding-top: 8px !important">
                              </v-currency-field>

                              <div 
                                style="
                                  display: flex;
                                  justify-content: space-between;
                                  padding-top:8px;">

                                <div class="text-required">

                                  Wajib
                                </div>

                                <div style="font-size: 14px;" class="text-red">

                                  <span style="font-weight: bold">

                                    <small>
                                      Inputkan minus untuk mengurangi stok.
                                    </small>

                                  </span>
                                </div>

                              </div>

                            </v-col>

                            <v-col cols="12">

                              <div 
                                class="text-title" 
                                style="font-size: 13px">

                                Catatan Penyesuaian
                              </div>

                              <v-text-field
                                v-model="item.note"
                                placeholder="Tuliskan disini"
                                required
                                color="#d31145"
                                maxlength="50"
                                autocomplete="off"
                                hide-details="auto"
                                class="py-0"
                                style="padding-top: 8px !important">
                              </v-text-field>

                              <div 
                                style="
                                  display: flex;
                                  justify-content: space-between;
                                  padding-top: 8px;">

                                <div class="text-optional">
                                  Optional
                                </div>

                                <div style="font-size: 11px;">

                                  <span style="font-weight: bold">
                                    {{ item.note.length }} 
                                  </span>

                                  <span style="color:#B5B5B5"> 
                                    dari 100 karakter
                                  </span>  

                                </div>

                              </div>

                            </v-col>

                          </v-row>

                        </v-list-item-subtitle>

                      </div>

                      <!-- <v-list-item-subtitle 
                        class="
                          text-end 
                          pt-0 
                          font-italic 
                          mt-3" 
                        style="
                          color: #ACACAC;
                          font-size: 14px">

                        {{ item.date | dateFull }}

                      </v-list-item-subtitle> -->
                      
                    </v-list-item-content>

                  </v-list-item>

                  <v-divider :key="i" class="mx-auto"></v-divider>

                </template>

              </v-list>

              <!-- <div v-for="(item, i) in product_selected" :key="i" class="d-flex justify-space-between py-2">

                <div 
                  class="pa-1 pl-4 d-flex align-center"
                  style="
                    border: 2px solid rgba(211, 17, 69, 0.2);
                    flex:1;
                    height: 30px;
                    color: #d31145;
                    border-radius: 5px;
                    background-color: rgba(211, 17, 69, 0.1);">

                  {{ item.name }}

                </div>

                <div class="ml-4 pr-0 px-0">

                  <v-icon 
                    @click="removeProduct(i)" 
                    color="#d31145">
                    mdi-delete
                  </v-icon>

                </div>
              </div> -->
            </div>

            <div v-else>

              <v-alert
                class="pa-2"
                v-if="product_selected.length < 1"
                text
                dense
                color="#FF9800"
                icon="mdi-alert-outline">

                <div class="subtitle-2">
                  Silahkan Tambahkan product yang akan di sesuaikan
                </div>

              </v-alert>
            </div>

          </div>
          
        </v-card-text>


        <v-btn 
          class="float white--text text-capitalize" 
          :loading="process.run" 
          @click="toValidation()" 
          width="150" 
          rounded 
          color="#D31145">

          <v-icon 
            color="#fff" 
            class="my-float" 
            left>
            mdi-send
          </v-icon>

          Sumbit
        </v-btn>

      </v-card>

    </v-dialog>

    <v-bottom-sheet v-model="dialog.confirmation" width="100%">
      <v-sheet
        class="text-left rounded-t-xl"
        height="214px"
        width="100%">
        <div 
          class="pa-4"
          style="
            font-size: 18px;
            color: #d31145; 
            font-weight: bold;
            height:60px;">
          Tambah Penyesuaian Stok
        </div>

        <v-divider></v-divider>

        <div 
          class="
            d-flex 
            flex-wrap 
            align-content-center 
            align-center" 
          style="
            font-size: 14px;
            padding:10px 20px 5px 20px;
            height: 96px;">

          Apakah anda yakin ingin menambahkan Penyesuaian Stok ?

        </div>

        <div 
          class="
            d-flex 
            justify-star 
            align-center 
            px-4 
            pb-4" 
          style="
            position: absolute;
            bottom: 0; 
            width: 100%">

          <v-btn 
            @click="submit()" 
            style="flex:1" 
            :loading="process.run" 
            :disabled="process.run" 
            rounded
            elevation="0" 
            color="#d31145" 
            class="
              body-1 
              d-flex 
              justify-start 
              text-capitalize 
              mr-2 
              white--text">

            <v-icon>
              mdi-check-circle-outline
            </v-icon>

            <span 
              style="
                display: flex;
                justify-content: end;
                width:100%">

              Ya, Tambahkan Data
            </span>

          </v-btn>

          <v-btn
            rounded
            outlined
            style="flex: .5"
            color="#d31145"
            class="body-1 text-capitalize"
            :disabled="process.run"
            @click="dialog.confirmation = false">

            Batalkan

          </v-btn>

        </div>

      </v-sheet>

    </v-bottom-sheet>

  </div>
</template>

<script>
export default {
  data: () => ({
    searching: "",
    searching_product: "",
    stock_adjustment_list: [],
    product_list: [],
    product_selected: [],
    process: {
      run: false
    },
    dialog: {
      form: false,
      selected: false,
      confirmation: false,
    }
  }),
  components: {

  },
  computed: {

  },
  watch: {
    searching: _.debounce(function(newVal) {
      this.searching = newVal;

      this.initialize();
    }, 500),
    searching_product: _.debounce(function(newVal) {
      this.searching = newVal;

      this.getProduct();
    }, 500),
  },
  created() {
    this.initialize()
    this.getProduct()
  },
  mounted(){

  },
  methods: {
    async openDialog(){
      this.dialog.selected = true;
      await this.getProduct()
    },
    closeDialog(){
      this.dialog.form = false
      this.product_selected = []
    },
    async getProduct(){
      this.process.run = true
      let params = {
        limit: 10000,
        search: this.searching_product,
      }
      
      let res = await this.$get(`pos/product`, params)

      if (res.status == 200) {
        this.product_list = res.results.data
        this.process.run = false
      }
    },
    addData(item){
      console.log(this.product_selected);
      if (this.product_selected.length > 0) {
        console.log(this.product_selected);
        let array_product = this.product_selected.filter(prod => {
          return item.id == prod.id
        });

        if(array_product.length > 0 && item.id == array_product[0].id){
          this.dialog.selected = false
          this.$store.state.snackbar.state = true
          this.$store.state.snackbar.content = `Produk ${item.name} sudah di pilih. Silahkan pilih lainnya.`
          return
        }else{
          let data = {
            id:  item.id,
            name: item.name,
            image_url: item.image_url,
            sku: item.sku,
            qty: 0,
            note: ''
          }

          this.product_selected.unshift(data);
          this.dialog.selected = false;
        }
      }else{
        let data = {
          id:  item.id,
          name: item.name,
          image_url: item.image_url,
          sku: item.sku,
          qty: 0,
          note: ''
        }

        this.product_selected.unshift(data);
        this.dialog.selected = false;
      }
    },
    removeProduct(index){
      this.product_selected.splice(index, 1)
    },
    async initialize(){
      this.process.run = true;

      let params = {
        search: this.searching,
        sort: "",
        dir: "",
        limit: 15,
        page: 1
      }

      let res = await this.$get(`pos/stock-adjustment`, params)

      if (res.status == 200) {
        this.process.run = false
        this.stock_adjustment_list = res.results.data
      }
    },
    toValidation(){

      if (this.product_selected.length > 0) {
        this.dialog.confirmation = true
      }else{
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = 'Anda belum memilih produk yang ingin di sesuaikan'
      }
    },
    async submit() {
      this.$store.state.overlay.state = true
      this.dialog.confirmation = false
      let data = {
        product: JSON.stringify(this.product_selected)
      }

      let res = await this.$post(`pos/stock-adjustment/add`, data)

      if (res.status == 200) {
        this.$store.state.overlay.state = false
        this.initialize()
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = res.message
        this.dialog.form = false
        this.product_selected = []
      }
    }
  }
}
</script>

<style>

</style>